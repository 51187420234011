<template>
  <CHeader fixed>
    <CHeaderNav class="mr-auto">
      <div class="ml-4 logo">
        <img src="@/assets/icons/lsxvip.png">
      </div>
    </CHeaderNav>

    <CHeaderNav class="mr-4 ml-auto">
      <TheHeaderDropdownAccnt/>
    </CHeaderNav>
  </CHeader>
</template>

<script>
import TheHeaderDropdownAccnt from './TheHeaderDropdownAccnt'

export default {
  name: 'TheHeader',
  components: {
    TheHeaderDropdownAccnt
  }
}
</script>
<style lang="scss">
.logo {
  img {
    height: 50px;
  }
}
</style>
